
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { mapState } from 'vuex'
import { showError } from '~/utils'
import AgoraService from '../../utils/agora'

export default {
  data() {
    return {
      timer: null,
      time: {
        second: 0,
        minute: 0,
        hour: 0,
      },
      isMute: false,
    }
  },
  computed: {
    ...mapState({
      call: state => state.chat.call,
      callStarted: state => state.chat.callStarted,
    }),
    order() {
      if (this.call) return this.call.order
      return null
    },
  },
  watch: {
    callStarted(val) {
      if (val) {
        this.startTimer()
      } else {
        this.stopTimer()
      }
    },
  },
  mounted() {
    if (this.callStarted) {
      this.startTimer()
    }
  },
  beforeDestroy() {
    this.stopTimer()
  },
  methods: {
    get,
    muteAudio() {
      const agora = new AgoraService()
      if (!this.isMute) {
        agora.mute()
        this.isMute = true
      } else {
        agora.unmute()
        this.isMute = false
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    startTimer() {
      const now = this.$dayjs()
      const diffTime = now.diff(this.$dayjs(), 's')
      const getSeconds = diffTime % 60
      const getMinute = Math.floor(diffTime / 60) > 60 ? Math.floor(diffTime / 60) % 60 : Math.floor(diffTime / 60)
      const getHour = Math.floor(diffTime / 3600)

      this.time = {
        hour: getHour,
        minute: getMinute,
        second: getSeconds,
      }

      this.timer = setInterval(() => {
        if (this.time.minute + 1 === 60) {
          this.time.hour += 1
          this.time.minute = 0
        }
        if (this.time.second + 1 === 60) {
          this.time.minute += 1
          this.time.second = 0
        } else {
          this.time.second += 1
        }
      }, 1000)
    },
    async rejectCall() {
      this.isLoading = true
      try {
        const order = cloneDeep(this.call.order)
        order.isCalling = false
        await this.$axios.patch(`orders/${order.id}`, order)
        this.showModal = false
      } catch (error) {
        showError(error)
      }
      this.isLoading = false
    },
  },
}
